import React, { Component } from 'react'
import Image from 'next/image'
import Link from 'next/link'

// Material UI
import { Box, Container, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme)=>({
    root: {
    },
    container: {
        // TODO: check if this approach fits our needs for footer
        position: 'relative',
        bottom: '0px',
        height: '10vh',
        minHeight: '170px',
        display: 'grid',
        gridTemplateColumns: '100vw',
        gridTemplateRows: '101px 68px',
        background: 'white',
    },
    image_container:{
        height: '100%',
        width: "40%",
        position: "relative",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    image_container_mobile:{
        height: '83px',
        width: "25%",
        position: "relative",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image:{
        height: "59px",
    },
    footer_container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',        
        boxShadow: '0 4px 11px 2px rgba(0, 0, 0, 0.29)',
    },
    footer_menu:{
        width: '65%',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '14px'
    },
    footer_mobile_menu:{
        width: '80%',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '14px'
    },
    footer_mobile: {
        height: '136px',
        position: 'relative'
    },
    footer_container_mobile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '53px'
    },
    footer_mobile_label: {
        color: '#546e7a',
        fontSize: '11px',
        textAlign: 'center'
    }
}));

const Footer = () =>{

    const classes = useStyles();

    return (
        <>
            <Hidden smDown>
                <Box component="footer" className={classes.container}>
                    <Container className={classes.image_container}>
                        <Link href="/">
                        <Image 
                            // flexGrow={1}
                            component="img" 
                            src="/images/logos/isotipo.svg"
                            width='77px'
                            height='59px'
                            //    layout='fill'
                            //    objectFit="contain"
                            //    className={classes.image}
                            />
                        </Link>
                        <Box my={2} textAlign='center'>
                            Todos los derechos reservados ® Motofácil 2021
                        </Box>
                    </Container>
                    <Box className={classes.footer_container}>
                        <Container className={classes.footer_menu}>
                            <Link href="/contacto"><a>Contacto </a></Link>
                            <Link href="/faqs"><a>Preguntas frecuentes </a></Link>
                            <Link href="/terminosycondiciones"><a>Términos y condiciones </a></Link>
                            <Box minWidth="135px" display="flex" justifyContent="space-evenly">
                                <a>Síguenos en:</a>
                                <Link href="https://www.facebook.com/motofacilmx">
                                    <span style={{cursor:"pointer"}}><Image src="/images/static/atom-icon-medium-facebook_2021-06-09/atom-icon-medium-facebook.webp" width="20px" height="20px"></Image></span>
                                </Link>
                                <Link href="https://www.instagram.com/motofacil.mx/">
                                    <span style={{cursor:"pointer"}}><Image src="/images/static/atom-icon-medium-instagram_2021-06-09/atom-icon-medium-instagram.webp" width="20px" height="20px"></Image></span>
                                </Link>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Box component="footer" className={classes.footer_mobile}>
                    <Container className={classes.image_container_mobile}>
                        <Link href="/">
                            <Image 
                            // flexGrow={1}
                            component="img" 
                            src="/images/logos/isotipo.svg"
                            width='77px'
                            height='59px'
                            //    layout='fill'
                            //    objectFit="contain"
                            //    className={classes.image}
                            />
                        </Link>
                    </Container>
                    <Box className={classes.footer_container_mobile}>
                        <Container className={classes.footer_mobile_menu}>
                            <Typography className={classes.footer_mobile_label}>
                                Todos los derechos reservados ® Motofácil 2021
                            </Typography>
                        </Container>
                    </Box>
                </Box>
            </Hidden>
        </>
    )
}

export default Footer;
